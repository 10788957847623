import { whyUsInfo } from "../../constants";
import { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { AnimationOnScroll } from "react-animation-on-scroll";
import CircleBgMain from "../CircleBg/CircleBgMain";
import logo from "../../assets/why-us/logo.png";
import image1 from "../../assets/why-us/careers.jpg";
import ImageMask from "../ImageMask";

export default function WhyUs() {
  const [openPlus, setOpenPlus] = useState(0);
  function handleClickPlus(id) {
    if (openPlus === id) setOpenPlus(0);
    else setOpenPlus(id);
  }
  return (
    <section id="whyUs" className="relative">
      <CircleBgMain />
      <div className="flex flex-col md:flex-row justify-between gap-16">
        <div className="flex flex-col w-[1/2] md:max-w-[40%] gap-2">
          <AnimationOnScroll
            animateIn="animate__slideInDown"
            className="py-24 md:py-36"
          >
            <p className=" text-primaryBlue font-bold uppercase">why us</p>
            <h1 className="text-black text-lg md:text-xl lg:text-2xl font-bold">
              We have over 15+ years in the Tech Industry
            </h1>
            <p className="text-sm text-textGray">
              We provide best solution to fit your requirement,budget and
              schedule.
            </p>
            <div className="flex flex-col gap-2 pt-8">
              {whyUsInfo.map((info) => (
                <div
                  className="flex gap-2 cursor-pointer"
                  onClick={() => handleClickPlus(info.id)}
                >
                  <div className=" text-primaryBlue pt-1">
                    {openPlus === info.id ? <FaMinus /> : <FaPlus />}
                  </div>
                  <div className="flex flex-col gap-2">
                    <h1 className=" uppercase font-bold">{info.title}</h1>
                    <p
                      className={`${
                        openPlus === info.id ? "slide-in-down" : "hidden"
                      } text-sm text-textGray`}
                    >
                      {info.text}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </AnimationOnScroll>
        </div>

        {/* images */}
        <div className="w-[55%] hidden md:block">
          <div className=" relative pt-8 pr-8 h-full w-full">
            <AnimationOnScroll
              animateIn="animate__slideInRight"
              className=" absolute h-full w-full top-[10%] right-[-25%]"
            >
              <img src={logo} alt="" className="h-[80%] w-[70%]" />
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__slideInUp"
              className="w-2/3 relative top-[10%] -right-[6%]"
            >
              <div className="w-full max-w-[350px]">
                <ImageMask image={image1} />
              </div>
            </AnimationOnScroll>
          </div>
          {/* <div className="mt-6 md:mt-0 gap-4 md:gap-8 grid grid-cols-3">
            <div className="h-full cursor-pointer hover:scale-110 duration-500">
              <img
                src={smallImage}
                alt=""
                className=" object-cover w-full h-full rounded-md"
              />
            </div>
            <div className="h-full col-span-2 cursor-pointer hover:scale-110 duration-500">
              <img
                src={bigImage}
                alt=""
                className=" object-cover w-full h-full rounded-md"
              />
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}
