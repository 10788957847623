import { BrowserRouter } from "react-router-dom";
import { Route, Link, Routes } from "react-router-dom";
import ElvSystem from "./ElvSystem";
import MainPage from "./MainPage";
import "animate.css/animate.min.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<MainPage />} />
        <Route exact path="/ElvSystem/:id" element={<ElvSystem />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
