import circle from "../../assets/circle-shape/circle-shape2.svg";

export default function CircleBg2() {
  return (
    <>
      <div
        className="hidden lg:block absolute w-[200px] top-[0%] sm:top-[-10%]
        sm:w-[350px]
      left-[-20%] lg:top-[-35%] lg:w-[500px]"
      >
        <img src={circle} alt="circle" />
      </div>
      <div className=" hidden lg:block absolute left-[10%] top-[95%] w-[200px]">
        <img src={circle} alt="circle" />
      </div>
    </>
  );
}
