import Social from "../Social";
import homeImage3 from "../../assets/home/AdobeStock_210409557.jpeg";
import star from "../../assets/Star.png";
import arrow from "../../assets/Arrow.png";
import mainImage from "../../assets/home/maintenance.jpg";
import homeImage2 from "../../assets/home/Home-Automation-Installers-New-York.jpg";
import { FaPhone } from "react-icons/fa6";
import ImageMask from "../ImageMask";
import CircleBg from "../CircleBg/CircleBg";
import { TypeAnimation } from "react-type-animation";

export default function Home() {
  return (
    <section
      id="home"
      className="pt-14 pb-[75px] bg-primaryBlue pl-4 xs:pl-16 md:pl-24 lg:pl-32 xl:pl-44 relative overflow-hidden"
    >
      <CircleBg />
      <div className="flex flex-col md:flex-row w-full md:justify-between relative justify-center items-center">
        <div
          className=" absolute left-[15%] rotate-45
           ss:hidden md:block md:left-[23%] md:top-[50%] md:-rotate-[20deg] md:-scale-x-[1]
           lg:scale-x-[1] lg:left-[33%]  lg:-rotate-[30deg] xl:rotate-0 xl:top-[35%] pt-24"
        >
          <img src={arrow} alt="arrow" className="w-[15vw] float" />
        </div>
        <div
          className="flex flex-col justify-center md:justify-start gap-4 text-white max-w-[450px] z-60 pt-24 md:w-1/2
        text-center md:text-left pr-4 md:pr-0"
        >
          <div className="text-2xl md:text-4xl font-bold whitespace-pre-line ">
            <TypeAnimation
              sequence={[
                // Same substring at the start will only be typed out once, initially
                "IT Consulting Services \nFor Your Business",
                1000, // wait 1s before replacing "Mice" with "Hamsters"
                "IT Consulting Services \nFor Your Corporate",
                1000,
                "IT Consulting Services \nFor Your Company",
                1000,
                "IT Consulting Services \nFor Your Home",
                1000,
              ]}
              wrapper="span"
              speed={50}
              style={{ display: "inline-block" }}
              repeat={Infinity}
            />
          </div>

          <p className="text-sm lg:text-base">
            MacTech Smart Technologies, established in 2011, is a corporate
            professional organization that specializes in providing full range
            of innovative and integrated high end smart technology solutions to
            corporate and individual customers in the State of Qatar.
          </p>
          <div className="flex flex-col gap-6 lg:hidden">
            <div className="flex flex-col justify-start gap-1">
              <p className="text-[10px]">(4,5/5) Rating Star by Clients</p>
              <img
                src={star}
                alt="star"
                className="w-1/4 self-center md:self-start"
              />
            </div>
              <div className="flex justify-center md:justify-start items-center gap-1">
                <div className="p-3 text-xl phone">
                  <FaPhone />
                </div>
                <div className="flex flex-col gap-2">
                <a href="tel:77075511"><p className=" underline text-sm"> +974 7707 5511</p></a>
                <a href="tel:74036478"><p className=" underline text-sm"> +974 7403 6478</p></a>
                </div>
                
              </div>
          </div>
          <div className="md:mt-4 self-center md:self-start">
            <Social />
          </div>
        </div>
        <div className="flex text-white justify-center lg:justify-between items-center w-1/2">
          <div className="lg:flex flex-col gap-6 hidden">
            <div className="flex flex-col justify-start gap-1">
              <p className="text-[10px]">(4,5/5) Rating Star by Clients</p>
              <img src={star} alt="star" className="w-1/2" />
            </div>
            <a href="tel:77075511">
              <div className="flex justify-start items-center gap-1">
                <div className="p-3 text-xl phone">
                  <FaPhone />
                </div>
                <div className="flex flex-col gap-2">
                <a href="tel:77075511"><p className=" underline text-sm"> +974 7707 5511</p></a>
                <a href="tel:74036478"><p className=" underline text-sm"> +974 7403 6478</p></a>
                </div>
              </div>
            </a>
          </div>
          {/* images */}
          <div className="w-2/3 lg:w-2/3 pt-16 lg:pt-32 relative">
            <div className="w-full max-w-[450px] h-[80%]">
              <ImageMask image={mainImage} />
            </div>
            <div className=" absolute w-[40%] -left-[12%] top-[12%]">
              <ImageMask image={homeImage2} />
            </div>
            <div className=" absolute w-[30%] left-[10%] top-[80%]">
              <ImageMask image={homeImage3} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
