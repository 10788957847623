import CircleBg from "../CircleBg/CircleBg";
import { navLinks, elvSystem } from "../../constants";
import logo from "../../assets/logo.png";
import { Outlet, Link } from "react-router-dom";
import {
  FaEnvelopeOpen,
  FaPhone,
  FaLocationDot,
  FaGreaterThan,
  FaInbox,
} from "react-icons/fa6";
import Social from "../Social";

export default function Footer() {
  return (
    <footer
      id="contact"
      className="px-4 xs:px-16 md:px-24 lg:px-42 xl:px-64 relative bg-primaryBlue text-white overflow-hidden
    flex flex-col gap-16 py-12"
    >
      <CircleBg />
      <div className=" hidden sm:flex flex-row justify-between items-center py-8 border-b-[1px] border-lightBlue z-10">
        <div className="flex gap-2 justify-start items-center">
          <FaPhone />
          <div className="flex flex-col text-sm font-bold">
                <a href="tel:77075511"><p> +974 7707 5511</p></a>
                <a href="tel:74036478"><p> +974 7403 6478</p></a>
                </div>
        </div>
        <div className="flex gap-2 justify-start items-center">
          <FaEnvelopeOpen />
          <div className="flex flex-col">
            <a href="mailto:info@mactech.qa" className="text-sm font-bold">info@mactech.qa</a>
            <p className=" text-xs">Get a Qutation</p>
          </div>
        </div>
        <div className="flex gap-2 justify-start items-center">
          <FaLocationDot />
          <div className="flex flex-col">
            <h1 className="text-sm font-bold">Qatar - Doha</h1>
            <p className=" text-xs">Doha</p>
          </div>
        </div>

        <div className="flex gap-2 justify-start items-center">
          <FaInbox />
          <div className="flex flex-col">
            <h1 className="text-sm font-bold">P.O.BOX 4994,Doha,Qatar</h1>
            <p className=" text-xs">Doha</p>
          </div>
        </div>
      </div>
      <div className="sm:flex sm:justify-between sm:items-center sm:py-4 z-10">
        <div className="flex flex-col gap-4">
          <a href="#home" alt="logo">
            <img src={logo} alt="logo" width={132} />
          </a>
          <p className="text-sm max-w-64">
            Best solution to fit your requirement,budget and schedule.
          </p>
          <Social />
          <hr className="sm:hidden mt-6"></hr>
          <div className="sm:hidden flex justify-between items-center pt-8">
            <div className="flex flex-col gap-6">
              <div className="flex gap-2 justify-start items-center">
                <FaPhone />
                <div className="flex flex-col text-sm font-bold">
                <a href="tel:77075511"><p> +974 7707 5511</p></a>
                <a href="tel:74036478"><p> +974 7403 6478</p></a>
                </div>
              </div>
              <div className="flex gap-2 justify-start items-center">
                <FaEnvelopeOpen />
                <div className="flex flex-col">
                <a href="mailto:info@mactech.qa" className="text-sm font-bold">info@mactech.qa</a>
                  <p className=" text-xs">Get a Qutation</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <div className="flex gap-2 justify-start items-center">
                <FaLocationDot />
                <div className="flex flex-col">
                  <h1 className="text-sm font-bold">Qatar - Doha</h1>
                  <p className=" text-xs">Doha</p>
                </div>
              </div>

              <div className="flex gap-2 justify-start items-center">
                <FaInbox />
                <div className="flex flex-col">
                  <h1 className="text-sm font-bold">P.O.BOX 4994,Doha,Qatar</h1>
                  <p className=" text-xs">Doha</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden sm:flex flex-col gap-4">
          <h1 className="text-sm font-bold uppercase">Links</h1>
          <ul className="flex flex-col gap-4">
            {navLinks.map((nav) => (
              <li key={nav.id} className="cursor-pointer text-[16px] ">
                <div className="flex text-xsm justify-start items-center gap-2">
                  <FaGreaterThan className="text-[8px]" />
                  <a href={`#${nav.id}`} className="nav-link">
                    {nav.title}
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="hidden sm:flex flex-col gap-4">
          <h1 className="text-sm font-bold uppercase">Elv System</h1>
          <ul className="flex flex-col gap-4">
            {elvSystem.map((elv) => (
              <li key={elv.id} className="cursor-pointer text-[16px] ">
                <div className="flex text-xs justify-start items-center gap-2">
                  <FaGreaterThan className="text-[8px]" />
                  <Link to={`/ElvSystem/${elv.id}`} className="nav-link">
                    {elv.title}
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="flex justify-center items-end pt-8 z-10">
        <p className="text-xs">
          Copyright By Mactech © 2024, All Rights Reserved
        </p>
      </div>
      <Outlet />
    </footer>
  );
}
