import {
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
  client7,
  client8,
  client9,
  client10,
  client11,
  client12,
  client13,
  client14,
  client15,
  client16,
} from "../assets";
import {
  accessControl1,
  accessControl2,
  accessControl3,
  accessControl4,
  accessControl5,
  accessControlLogo1,
  accessControlLogo2,
  accessControlLogo3,
  homeauto1,
  homeauto2,
  homeauto3,
  homeauto4,
  homeauto5,
  homeautoLogo1,
  homeautoLogo2,
  door1,
  door2,
  door3,
  door4,
  door5,
  doorLogo1,
  doorLogo2,
  phone1,
  phone2,
  phone3,
  phone4,
  phone5,
  phoneLogo1,
  phoneLogo2,
  phoneLogo3,
  tv1,
  tv1Logo1,
  tv1Logo2,
  tv2,
  tv3,
  tv4,
  tv5,
  AddressSystem1,
  AddressSystem2,
  AddressSystem3,
  AddressSystem4,
  AddressSystem5,
  AddressSystemLogo1,
  AddressSystemLogo2,
  AddressSystemLogo3,
  LAN1,
  LAN2,
  LAN3,
  LAN4,
  LAN5,
  LANLogo1,
  LANLogo2,
  LANLogo3,
  LANLogo4,
  LANLogo5,
  homeTheater1,
  homeTheater2,
  homeTheater3,
  homeTheater4,
  homeTheater5,
  homeTheaterLogo1,
  homeTheaterLogo2,
  homeTheaterLogo3,
  homeTheaterLogo4,
} from "../assets";
export const navLinks = [
  {
    id: "home",
    title: "Home",
  },

  {
    id: "elvSystem",
    title: "ELV System",
  },
  {
    id: "whyUs",
    title: "Why Us",
  },
  {
    id: "itServices",
    title: "IT Services",
  },
  {
    id: "clients",
    title: "Clients",
  },
  {
    id: "contact",
    title: "Contact us",
  },
];

export const whyUsInfo = [
  {
    id: 1,
    title: "Customized Solutions",
    text: "Mactech specializes in delivering tailor-made network solutions designed to meet the unique requirements of each client and project.",
  },
  {
    id: 2,
    title: "Client-Centric Approach",
    text: "Mactech prioritizes client satisfaction.",
  },
  {
    id: 3,
    title: "Flexibility and Support",
    text: "Mactech is a flexible IT company set up to offer assistance at any stage from your first concept design or proposal development to final commissioning and handover.",
  },
  {
    id: 4,
    title: "Quality Assurance",
    text: "We remain committed to excellence with ongoing post-delivery support, ensuring our solutions consistently exceed expectations.",
  },
];

export const elvSystem = [
  {
    id: 1,
    title: "Home Automation",
    text: "Home Automation Systems include a very broad range of technologies for monitoring and controlling almost everything in the building.",
    description1:
      "Home Automation Systems include a very broad range of technologies for monitoring and controlling almost everything in the building.",
    description2:
      "Home Automation Systems include a very broad range of technologies for monitoring and controlling almost everything in the building from lights to doors to home appliances and audio equipment and in short whatever works with electricity in the building. Most home automation solutions are now network based and give the option of remotely controlling and monitoring the building over the internet. While in the past, most home automations were using some remote control device, with advance of technology the means of control is now moved to smartphone and tablet applications or voice recognition solutions.",
    images: [homeauto1, homeauto2, homeauto3, homeauto4, homeauto5],
    logoImages: [homeautoLogo1, homeautoLogo2],
  },
  {
    id: 2,
    title: "IP Door Entry Systems",
    text: "Secure your property with our IP door entry systems, providing advanced access control and monitoring.",
    description1:
      "Secure your property with our IP door entry systems, providing advanced access control and monitoring.",
    description2:
      "Combining a door intercom with a phone allows to comfortably talk to a visitor and open the door from your desk. The conversation is handled by your telephony server, like a regular internal phone call.",
    images: [door1, door2, door3, door4, door5],
    logoImages: [accessControlLogo2, doorLogo2, doorLogo1, accessControlLogo3],
  },
  {
    id: 3,
    title: "Communication Systems",
    text: "IP Communication system provide effcient communication solutions for businesses and organizations.",
    description1:
      "IP Communication system provide effcient communication solutions for businesses and organizations.",
    description2:
      "An IP PBX is a private branch exchange (telephone switching system within an enterprise) that switches calls between VoIP (voice over Internet Protocol or IP) users on local lines while allowing all users to share a certain number of external phone lines.",
    images: [phone1, phone2, phone3, phone4, phone5],
    logoImages: [phoneLogo1, phoneLogo2, phoneLogo3],
  },
  {
    id: 4,
    title: "Access Control Systems",
    text: "We offer advanced access control solutions to secure your premises and manage access efficiently.",
    description1:
      "We offer advanced access control solutions to secure your premises and manage access efficiently.",
    description2:
      "Access Control System. ACS systems give access to different building locations (usually implemented by automatic unlocking of doors) through different means of authentication of people (by magnetic or RFID identification cards, by finger print, IRIS or face recognition).",
    images: [
      accessControl1,
      accessControl2,
      accessControl3,
      accessControl4,
      accessControl5,
    ],
    logoImages: [accessControlLogo1, accessControlLogo2, accessControlLogo3],
  },
  {
    id: 5,
    title: "SMATV (Satellite Master)",
    text: "Enjoy high-quality television reception with our SMATV systems, suitable for residential and commercial properties.",
    description1:
      "Enjoy high-quality television reception with our SMATV systems, suitable for residential and commercial properties.",
    description2:
      "SMATV (Satellite Master Antenna Television), CATV (Cable Television), and IPTV (IP Television) all explain different technologies to provide a TV distribution system within a building, interconnecting multiple television sets to a single source (usually called Head End) so each television can select the desired watching channel from a selection list.",
    images: [tv1, tv2, tv3, tv4, tv5],
    logoImages: [tv1Logo1, tv1Logo2],
  },
  {
    id: 6,
    title: "Public Address System (PAS/PAGA)",
    text: "is the speaker system installed in buildings for making announcements, playing background music and broadcasting pre-recorded alarm notifications.",
    description1:
      "PAS (Public Address System) or PAGA (Public Address and General Alarm) is the speaker system installed in buildings for making announcements, playing background music and broadcasting pre-recorded alarm notifications, sometimes automatically triggered by fire alarm systems",
    description2:
      "PAS is probably one of the few ELV systems that is still not much IP based and use twisted-pair electrical cables for connecting the distributed speakers to the power amplifiers.",
    images: [
      AddressSystem1,
      AddressSystem2,
      AddressSystem3,
      AddressSystem4,
      AddressSystem5,
    ],
    logoImages: [AddressSystemLogo1, AddressSystemLogo2, AddressSystemLogo3],
  },
  {
    id: 7,
    title: "LAN and WLAN",
    text: "LAN stands for Local Area Network,WLAN stands for Wireless LAN which is the network of wireless access points that provide wireless network coverage within and outside the building.",
    description1:
      "LAN stands for Local Area Network – also known as SCS (Structured Cabling System). Simply put, it is the data cabling in the building to enable users to network their computer devices and possibly access to internet.",
    description2:
      "In a small building, this can be simply CAT6 cabling from an MDF (Master Distribution Frame – i.e. equipment rack) while in larger infrastructures, you usually see multiple IDFs (Intermediate Distribution Frame) interconnected by fiber optic cables. (It is interesting to know that while we have no electricity passing fiber optic cables, they are still categorized as “ELV”!)\n\nWLAN stands for Wireless LAN which is the network of wireless access points that provide wireless network coverage within and outside the building.\n\nAs the technologies enhance, more and more other ELV systems depend on LAN infrastructure of buildings.",
    images: [LAN1, LAN2, LAN3, LAN4, LAN5],
    logoImages: [LANLogo1, LANLogo2, LANLogo3, LANLogo4, LANLogo5],
  },
  {
    id: 8,
    title: "Home Theater",
    text: "Transforming your entertainment experience with cutting-edge home theater solutions, delivering immersive audio and visual experiences.",
    description1:
      "Transforming your entertainment experience with cutting-edge home theater solutions, delivering immersive audio and visual experiences",
    description2:
      "A home theater is a dedicated room that has a large high resolution projection screen coupled with a surround sound system dispersing incredible audio detail. These two elements capture your attention and help you escape into an experience",
    images: [
      homeTheater1,
      homeTheater2,
      homeTheater3,
      homeTheater4,
      homeTheater5,
    ],
    logoImages: [
      homeTheaterLogo1,
      homeTheaterLogo2,
      homeTheaterLogo3,
      homeTheaterLogo4,
    ],
  },
];
export const clientsInfo = [
  {
    id: 1,
    name: "Arab contractor trading Cont. Co.1",
    image: client1,
  },
  {
    id: 2,
    name: "The F Medical",
    image: client2,
  },
  {
    id: 3,
    name: "ALma Aluminum & steel Co.",
    image: client3,
  },
  {
    id: 4,
    name: "Alwehda Medical Group",
    image: client4,
  },
  {
    id: 5,
    name: "Digital print center",
    image: client5,
  },
  {
    id: 6,
    name: "SDImtkg Marketing redefined",
    image: client6,
  },
  {
    id: 7,
    name: "Aldar printing press",
    image: client7,
  },
  {
    id: 8,
    name: "DALLAH advertising agency",
    image: client8,
  },
  {
    id: 9,
    name: "Alahmadani medical center",
    image: client9,
  },
  {
    id: 10,
    name: "ENANA group",
    image: client10,
  },
  {
    id: 11,
    name: "HADU projects",
    image: client11,
  },
  {
    id: 12,
    name: "Diet center",
    image: client12,
  },
  {
    id: 13,
    name: "Doha Regional plastic solution",
    image: client13,
  },
  {
    id: 14,
    name: "Eni",
    image: client14,
  },
  {
    id: 15,
    name: "Friendly food",
    image: client15,
  },
  {
    id: 16,
    name: "Sharq low ferm",
    image: client16,
  },
];

export const itServicesInfo = [
  {
    id: 1,
    title: "Data & Voice Network Solutions",
    text: "Stay connected and communicate efficiently with our advanced network services, ensuring smooth data transfer and clear voice communication.",
  },
  {
    id: 2,
    title: "Powerful Servers & Network Storage",
    text: "Our high-performance hardware forms the backbone of your business operations. Experience productivity like never before with our support.",
  },
  {
    id: 3,
    title: "Backup Solutions",
    text: "Safeguard your critical data with our reliable backup services, ensuring data recovery is a breeze.",
  },
  {
    id: 4,
    title: "Network & Computer Upgrades",
    text: "Keep your systems up to date and running at peak performance with our expert upgrade solutions.",
  },
  {
    id: 5,
    title: "Printers & Scanners",
    text: "Ensure document efficiently with our dependable printing and scanning solutions.",
  },
  {
    id: 6,
    title: "Domain and Website Services",
    text: "Establish a strong online presence with our domine registration and website services. We’ll make sure you stand out on the web.",
  },
  {
    id: 7,
    title: "Firewall & Security Syatems",
    text: "Protect your business from digital threats with our state-of-the-art security systems, safeguarding your assets and data.",
  },
  {
    id: 8,
    title: "IP Communication Systems",
    text: "Simplify communication with our IP-based solutions, ensuring clarity and efficiency in every interaction.",
  },
  {
    id: 9,
    title: "Fingerprint and Machines",
    text: "Manage attendance effortlessly with our fingerprint-based systems, ensuring accurate and hassle-free tracking.",
  },
  {
    id: 10,
    title: "Wireless Solutions",
    text: "Embrace the freedom of wireless technology with our solutions for efficient connectivity and mobility.",
  },
  {
    id: 11,
    title: "Office 365 Services",
    text: "Enhance collaboration and productivity with our office 365 solutions, covering E-mail, Document Sharing and more.",
  },
  {
    id: 12,
    title: "General IT Services - AMC",
    text: "Ensure your IT systems run seamlessly with our comprehensive support, including costeffective annual contracts.",
  },
];
