import { useState } from "react";
import Service from "./Service";
import { elvSystem } from "../../../constants";
import { FaGreaterThan, FaLessThan } from "react-icons/fa6";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function Services() {
  const [section, setSection] = useState(1);
  return (
    <section id="elvSystem" className="pt-24 md:py-32 bg-white">
      <AnimationOnScroll animateIn="animate__slideInLeft">
        <div className="flex gap-4 md:gap-8 px-2 xs:px-8 md:px-12 lg:px-20 xl:px-32 justify-between items-center">
          <div
            className={`flex justify-center items-center text-primaryBlue cursor-pointer pt-32 slide-left
          ${section === 1 ? " opacity-0" : " opacity-100"}`}
            onClick={() => setSection(1)}
          >
            <FaLessThan />
            <div className="hidden md:block">
              <FaLessThan />
            </div>
            <div className="hidden md:block">
              <FaLessThan />
            </div>
          </div>

          <div className="flex flex-col justify-center items-center gap-12 overflow-hidden">
            <div className="flex flex-col gap-4 font-bold justify-center items-center">
              <h1 className=" uppercase text-primaryBlue text-center">
                ELV System
              </h1>
              <p className="text-xl md:text-3xl text-black text-center">
                We provide all the new modern technologies that are increasingly
                becoming must-have systems in every building
              </p>
            </div>

            <div
              className="w-[200%] grid overflow-hidden md:grid-cols-8 grid-cols-4 gap-[1%] grid-rows-2 md:grid-rows-1
           pb-4 gap-y-[3%]"
            >
              {elvSystem.map((elv) => (
                <div
                  className={` duration-500 ${
                    section === 1 && "translate-x-[104%] md:translate-x-[218%]"
                  }
              ${
                section === 2 && " translate-x-[-104%] md:translate-x-[-218%]"
              }`}
                >
                  <Service
                    text={elv.text}
                    title={elv.title}
                    key={elv.id}
                    id={elv.id}
                  />
                </div>
              ))}
            </div>
          </div>

          <div
            className={`flex justify-center items-center text-primaryBlue cursor-pointer pt-32 slide-right
          ${section === 2 ? " opacity-0" : " opacity-100"}`}
            onClick={() => setSection(2)}
          >
            <FaGreaterThan />
            <div className="hidden md:block">
              <FaGreaterThan />
            </div>
            <div className="hidden md:block">
              <FaGreaterThan />
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </section>
  );
}
