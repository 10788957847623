import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa6";

export default function Social() {
  return (
    <div className="flex gap-2">
      <a
        href="https://www.facebook.com/mactechqa"
        className="hover:text-primaryOrange"
      >
        <FaFacebook />
      </a>
      <a href="" className="hover:text-primaryOrange">
        <FaTwitter />
      </a>
      <a
        href="https://instagram.com/mactechqatar?igshid=YmMyMTA2M2Y="
        className="hover:text-primaryOrange"
      >
        <FaInstagram />
      </a>
      <a href="" className="hover:text-primaryOrange">
        <FaLinkedin />
      </a>
    </div>
  );
}
