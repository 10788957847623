import circle from "../../assets/circle-shape/circle-shape.svg";

export default function CircleBg() {
  return (
    <>
      <div
        className=" absolute w-[200px] top-[-10%]
        sm:w-[350px]
      left-[-20%] lg:top-[-35%] lg:w-[500px]"
      >
        <img src={circle} alt="circle" />
      </div>
      <div className=" absolute left-[10%] top-[85%] w-[200px]">
        <img src={circle} alt="circle" />
      </div>
      <div
        className=" absolute w-[300px] top-[40%] left-[65%]
        sm:left-[85%]
      md:left-[65%] md:top-[-20%] md:w-[950px]"
      >
        <img src={circle} alt="circle" />
      </div>
    </>
  );
}
