import "./App.css";
import Navbar from "./components/Navbar";
import ElvItem from "./components/ElvItem";
import Footer from "./components/sections/Footer";

export default function ElvSystem() {
  return (
    <div className="flex flex-col font-inter">
      <Navbar background={true} />

      <div className="px-4 xs:px-16 md:pl-24 lg:pl-40 xl:pl-64 flex flex-col pt-28 md:pt-20">
        <ElvItem />
      </div>
      <Footer />
    </div>
  );
}
