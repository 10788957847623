import { useState, useEffect } from "react";
import CircleBg2 from "./CircleBg/CircleBg2";
import { useParams } from "react-router-dom";
import { elvSystem } from "../constants";

export default function ElvItem(props) {
  const [image, setImage] = useState(0);
  let id = parseInt(useParams().id, 10);
  const elvItem = elvSystem.filter((elv) => elv.id === id);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <>
      <div className="flex flex-col md:flex-row justify-center md:justify-end items-center mb-16">
        <div className="opacity-50">
          <CircleBg2 />
        </div>

        <div className="flex flex-col md:w-1/2 gap-2 max-w-[550px] text-center md:text-left md:mr-auto">
          <h1 className="font-bold text-xl">{elvItem[0].title}</h1>
          <p>{elvItem[0].description1}</p>
          <p className="whitespace-pre-line">{elvItem[0].description2}</p>
          <div className="flex gap-3 justify-start items-center pt-4">
            {elvItem[0].logoImages.map((image) => (
              <div className="">
                <img
                  className="max-h-[70px] w-auto rounded-lg"
                  src={image}
                  alt=""
                />
              </div>
            ))}
          </div>
        </div>

        <div className="grid gap-4 md:w-[40%] md:self-end">
          <div className="pt-6">
            <img
              className=" aspect-square h-[50hv] rounded-lg object-fill m-auto"
              src={elvItem[0].images[image]}
              alt=""
            />
          </div>
          <div className="grid grid-cols-5 gap-4">
            {elvItem[0].images.map((image, index) => (
              <div
                className=" cursor-pointer hover:scale-110 duration-500"
                onClick={() => setImage(index)}
              >
                <img
                  className="h-auto max-w-full rounded-lg"
                  src={image}
                  alt=""
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
