import { useState, useEffect } from "react";
import Client from "./Client";
import { clientsInfo } from "../../../constants";
import ClientsDot from "./ClientsDot";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function Clients() {
  const [client, setClient] = useState(1);
  useEffect(() => {
    const id = setInterval(
      () => setClient((oldCount) => (oldCount === 16 ? 1 : oldCount + 1)),
      2000
    );

    return () => {
      clearInterval(id);
    };
  }, []);
  return (
    <section id="clients" className="overflow-hidden py-24 md:py-36">
      <AnimationOnScroll animateIn="animate__slideInRight">
        <div className="flex gap-4 md:gap-8 px-2 xs:px-8 md:px-12 lg:px-20 xl:px-32 justify-center items-center overflow-hidden">
          <div className="flex flex-col justify-center items-center overflow-hidden">
            <div className="flex flex-col gap-4 font-bold justify-center items-center">
              <h1 className=" uppercase text-primaryBlue">Clients</h1>
              <p className="text-xl md:text-3xl text-black text-center">
                Our successful clients
              </p>
            </div>
            <div className="w-[400%] md:overflow-hidden overflow-x-scroll">
              <div className=" grid grid-cols-16 gap-[1%] px-12 md:px-24 pb-12 sm:pb-16 pt-12 translate-x-[39%] ">
                {clientsInfo.map((cl) => (
                  <div
                    onClick={() => setClient(cl.id)}
                    className={` duration-500 cursor-pointer
                  ${client === 2 && `-translate-x-[1%]`}
                  ${client === 3 && `-translate-x-[120%]`}
                  ${client === 4 && `-translate-x-[240%]`}
                  ${client === 5 && `-translate-x-[360%]`}
                  ${client === 6 && `-translate-x-[480%]`}
                  ${client === 7 && `-translate-x-[590%]`}
                  ${client === 8 && `-translate-x-[710%]`}
                  ${client === 9 && `-translate-x-[830%]`}
                  ${client === 10 && `-translate-x-[950%]`}
                  ${client === 11 && `-translate-x-[1070%]`}
                  ${client === 12 && `-translate-x-[1190%]`}
                  ${client === 13 && `-translate-x-[1310%]`}
                  ${client === 14 && `-translate-x-[1430%]`}
                  ${client === 15 && `-translate-x-[1550%]`}
                  ${client === 16 && `-translate-x-[1670%]`}`}
                  >
                    {client === cl.id ? (
                      <Client
                        image={cl.image}
                        name={cl.name}
                        active={true}
                        key={cl.id}
                      />
                    ) : (
                      <Client
                        image={cl.image}
                        name={cl.name}
                        active={false}
                        key={cl.id}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="md:flex gap-1 hidden pb-1">
              {clientsInfo.map((cl) => (
                <div
                  className=" cursor-pointer"
                  onClick={() => setClient(cl.id)}
                >
                  {client === cl.id ? (
                    <ClientsDot active={true} />
                  ) : (
                    <ClientsDot active={false} />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </section>
  );
}
