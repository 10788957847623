export default function ImageMask({ image }) {
  return (
    <div
      className="  bg-white p-1 md:p-2"
      style={{
        clipPath:
          " polygon(47% 0.4%,50% 0%,53% 0.4%,98% 22%, 100% 25%, 100% 75%, 98% 78%, 53% 99.6%,50% 100%,47% 99.6%, 2% 78%, 0% 75%, 0% 25% ,2% 22%)",
      }}
    >
      <img
        src={image}
        alt="mask"
        style={{
          clipPath:
            " polygon(47% 0.4%,50% 0%,53% 0.4%,98% 22%, 100% 25%, 100% 75%, 98% 78%, 53% 99.6%,50% 100%,47% 99.6%, 2% 78%, 0% 75%, 0% 25% ,2% 22%)",
          objectFit: "cover",
          aspectRatio: "1 / 1",
        }}
      />
    </div>
  );
}
