import { FaGreaterThan } from "react-icons/fa6";
import { Outlet, Link } from "react-router-dom";

export default function Service(props) {
  const id = props.id;
  return (
    <>
      <Link to={`/ElvSystem/${id}`}>
        <div
          className="h-full duration-500 pb-6"
          style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
        >
          <div className="flex flex-col gap-2 justify-start h-full">
            <div className="w-full h-[300px]">
              <img
                src={props.image}
                alt="client"
                className="w-full h-[300px] object-cover"
              />
            </div>
            <h1 className="text-primaryBlue font-bold px-2 pt-4">{props.title}</h1>
            <p className="text-[12px] text-textGray px-2">{props.text}</p>

            <div
              className="flex gap-2 text-[10px] md:text-sm font-bold justify-start items-center hover:gap-6 
          text-black duration-500 cursor-pointer px-2"
            >
              <p className=" uppercase ">Read More</p>
              <FaGreaterThan />
            </div>
          </div>
        </div>
      </Link>
      <Outlet />
    </>
  );
}
