import circle from "../../assets/circle-shape/circle-shapeMain.svg";

export default function CircleBgMain() {
  return (
    <>
      <div className="absolute 
      left-[60%] top-[60%] w-[300px]
     sm:left-[70%] sm:top-[50%] sm:w-[400px]
      md:left-[-50%] md:top-[45%] md:w-[600px]">
        <img src={circle} alt="circle" />
      </div>
    </>
  );
}
