import MobileService from "./MobileService";
import { elvSystem } from "../../../constants";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function MobileServices() {
  return (
    <section id="elvSystem" className="py-24 bg-white">
      
      <div className="flex flex-col justify-center items-center gap-12 px-2 xs:px-8">
      <AnimationOnScroll animateIn="animate__slideInDown">
          <div className="flex flex-col gap-4 font-bold justify-center items-center">
            <h1 className=" uppercase text-primaryBlue">ELV System</h1>
            <p className="text-xl text-black text-center">
              We provide all the new modern technologies that are increasingly
              becoming must-have systems in every building
            </p>
          </div>

          </AnimationOnScroll>
     
            {elvSystem.map((elv, index) => (
              index % 2 === 0 ? 
              <AnimationOnScroll animateIn="animate__slideInRight">
              <div className="px-6">
                <MobileService
                  text={elv.text}
                  title={elv.title}
                  image={elv.images[0]}
                  key={elv.id}
                  id={elv.id}
                />
              </div>
              </AnimationOnScroll>:
              <AnimationOnScroll animateIn="animate__slideInLeft">
              <div className="px-6">
                <MobileService
                  text={elv.text}
                  title={elv.title}
                  image={elv.images[0]}
                  key={elv.id}
                  id={elv.id}
                />
              </div>
              </AnimationOnScroll>
            ))}
        </div>
    </section>
  );
}
