import { FaGreaterThan } from "react-icons/fa6";
import { Outlet, Link } from "react-router-dom";

export default function Service(props) {
  const id = props.id;
  return (
    <>
      <Link to={`/ElvSystem/${id}`}>
        <div
          className="px-2 md:px-8 py-8 md:py-16 border-t-8 border-primaryBlue rounded-md shadow-primaryBlue md:rounded-br-[44px]
          rounded-br-[24px]  cursor-pointer box h-full"
          style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
          // onClick={navigateToELV}
        >
          <div className="flex flex-col gap-4">
            <h1 className="text-sm sm:text-base md:text-lg text-primaryBlue font-bold">
              {props.title}
            </h1>
            <p className="text-xs text-textGray hidden sm:block">
              {props.text}
            </p>

            <div
              className="flex gap-2 text-[10px] md:text-sm font-bold justify-start items-center hover:gap-6 
          text-black duration-500 cursor-pointer"
            >
              <p className=" uppercase ">Read More</p>
              <FaGreaterThan />
            </div>
          </div>
        </div>
      </Link>
      <Outlet />
    </>
  );
}
