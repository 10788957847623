import Button from "../Button";
import { itServicesInfo } from "../../constants";
import { FaArrowUp, FaArrowDown } from "react-icons/fa6";
import { useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function ItServices() {
  const [openService, setOpenService] = useState(0);
  function handleClickArrow(id) {
    if (openService === id) setOpenService(0);
    else setOpenService(id);
  }
  return (
    <section
      id="itServices"
      className="  bg-veryLightOrange py-24 flex flex-col gap-6"
    >
      <div
        className="flex flex-col sm:flex-row gap-4 justify-start sm:justify-between 
      items-start sm:items-center px-4 xs:px-16 md:px-24 lg:px-40 xl:px-64"
      >
        <AnimationOnScroll animateIn="animate__slideInLeft">
          <div className="flex flex-col gap-4 font-bold justify-start max-w-[550px]">
            <h1 className=" uppercase text-primaryBlue">
              COMPLETE IT SOLUTIONS
            </h1>
            <p className="text-xl md:text-3xl text-black">
              Our IT Services Designed to enhance Your Business Operations
            </p>
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__slideInRight">
          <Button
            title="Download profile"
            pathFile="/profile-pdf/Machtech-ITServices-Profile.pdf"
          />
        </AnimationOnScroll>
      </div>
      <AnimationOnScroll animateIn="animate__slideInUp">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 px-4 xs:px-16 md:px-24 lg:px-40 xl:px-64">
          {itServicesInfo.map((serv) => (
            <div className="flex flex-col gap-2">
              <div
                className="flex justify-between items-center bg-white py-3 px-6 cursor-pointer rounded-md"
                onClick={() => handleClickArrow(serv.id)}
              >
                <h1 className="text-sm font-[600]">{serv.title}</h1>

                {openService === serv.id ? (
                  <FaArrowUp className=" text-primaryOrange" />
                ) : (
                  <FaArrowDown className=" text-primaryOrange" />
                )}
              </div>
              <p
                className={`${
                  openService === serv.id ? "slide-in-down" : "hidden"
                } text-xs text-textGray py-1 px-6`}
              >
                {serv.text}
              </p>
            </div>
          ))}
        </div>
      </AnimationOnScroll>
    </section>
  );
}
