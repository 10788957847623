export default function Button({ title, pathFile }) {
  return (
    <a
      href={pathFile}
      download
      className=" bg-primaryOrange text-white py-2 px-6 hover:bg-white hover:text-primaryOrange duration-500 rounded-xl
    border-[1px] border-primaryOrange"
    >
      {title}
    </a>
  );
}
