import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./components/sections/Home";
import Loader from "./components/loader/Loader";
import WhyUs from "./components/sections/WhyUs";
import Footer from "./components/sections/Footer";
import Services from "./components/sections/services/Services";
import Clients from "./components/sections/clients/Clients";
import ItServices from "./components/sections/ItServices";
import ParallaxBg from "./components/ParallaxBg";
import { useEffect, useState } from "react";
import MobileServices from "./components/sections/services/MobileServices";

export default function MainPage() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className=" font-inter overflow-hidden">
      <Navbar />
      <Home />
      <div className="hidden sm:block ">
        <Services />
      </div>
      <div className="sm:hidden ">
        <MobileServices />
      </div>

      <div className=" overflow-hidden">
        <ParallaxBg />
      </div>

      <div className="pl-4 xs:pl-16 md:pl-24 lg:pl-40 xl:pl-64 flex flex-col bg-white">
        <WhyUs />
      </div>
      <ItServices />
      <div className="px-4 xs:px-16 md:px-24 lg:px-40 xl:px-64 flex flex-col bg-white">
        <Clients />
      </div>

      <Footer />
    </div>
  );
}
