import { useState, useEffect } from "react";
import close from "../assets/close.svg";
import menuBlack from "../assets/menuBlack.svg";
import menuWhite from "../assets/menuWhite.svg";
import { navLinks, elvSystem } from "../constants";
import logo from "../assets/logo.png";
import { FaEnvelope } from "react-icons/fa6";
import { Outlet, Link } from "react-router-dom";
import { HiChevronDown } from "react-icons/hi";
import Button from "./Button";

export default function Navbar({ background }) {
  const [dropMenu, setDropMenu] = useState(false);
  const [active, setActive] = useState("Home");
  const [toggleMenu, setToggleMenu] = useState(false);

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  function MobileDropMenu() {
    return (
      <div className={`${dropMenu ? " opacity-100 duration-700" 
                        : "opacity-0"}
                        flex justify-center items-center p-4 w-full m-auto h-0`}>
        <div className="flex flex-col text-[16px] font-[300] gap-2 h-0 text-center">
          {elvSystem.map((elv) => (
            <Link to={`/ElvSystem/${elv.id}`}>{elv.title}</Link>
          ))}
        </div>
      </div>
    );
  }
  

  function handleClickNavLink({ title }) {
    setToggleMenu(false);
  }
  return (
    <>
      <nav
        className={`flex justify-between items-center py-4 fixed z-50 w-full
     ${offset > 0 ? "shadow-xl duration-500 bg-white" : ""}
     ${background || offset > 0 ? "text-black" : "text-white"}`}
      >
        <div className="flex flex-col w-full">
          <div className="flex justify-between items-center px-4 xs:px-16 md:px-24 lg:px-32 xl:px-44">
            <Link to="/">
              <img
                src={logo}
                alt="logo"
                className={`w-[92px] duration-500  ${
                  offset === 0 && "md:w-[112px] lg:w-[142px]"
                }`}
              />
            </Link>
            <div className=" hidden lg:flex justify-center items-center gap-4">
              <ul className="flex lg:gap-6 xl:gap-10 justify-end items-end">
                {navLinks.map((nav) => (
                  <li key={nav.id} className="cursor-pointer text-[16px] ">
                    {nav.id === "elvSystem" ? (
                      <p
                        className={`nav-link cursor-pointer ${
                          background || offset > 0 ? " after:bg-black" : " after:bg-white"
                        }`}
                        onClick={() => setDropMenu(!dropMenu)}
                      >
                        <div className="flex gap-1 justify-center items-center">
                          {nav.title}
                          <HiChevronDown />
                        </div>
                      </p>
                    ) : (
                      <a
                        href={`/#${nav.id}`}
                        className={`
                          ${
                            active === nav.title
                              ? "nav-link active"
                              : "nav-link"
                          }
                    
                            ${
                              background || offset > 0 ? " after:bg-black" : " after:bg-white"
                            }
                      
                        `}
                        onClick={() => setActive(nav.title)}
                      >
                        {nav.title}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className="lg:flex justify-center items-center text-sm gap-1 hidden">
              <FaEnvelope />
              <a href="mailto:info@mactech.qa">info@mactech.qa</a>
            </div>
            <div className="lg:hidden">
              <img
                src={background || offset > 0 ? menuBlack : menuWhite}
                alt="menu"
                className="w-[28px] h-[28px] object-contain duration-500"
                onClick={() => setToggleMenu(!toggleMenu)}
              />
            </div>
            {/* Mobile menu */}
            <div
              className={`${
                toggleMenu ? "slide-in-right" : "slide-in-left"
              } absolute w-full bg-primaryBlue top-0 left-0 h-screen z-20 hidden`}
            >
              <div className="flex justify-between items-center p-8">
                <a href="#home" alt="logo">
                  <img src={logo} alt="logo" width={112} />
                </a>
                <img
                  src={close}
                  alt="menu"
                  className="w-[28px] h-[28px] object-contain"
                  onClick={() => setToggleMenu(false)}
                />
              </div>
              <div className="flex flex-col justify-between h-[70%] pt-16">
                <div className="flex justify-center items-center">
                  <ul className="flex flex-col gap-6 justify-center items-center">
                    {navLinks.map((nav) => (
                      <li
                        key={nav.id}
                        className="font-syne font-bold cursor-pointer text-2xl text-white"     
                      >
                        {nav.id === "elvSystem" ? (
                          <>
                      <p
                        className="cursor-pointer"
                        onClick={() => setDropMenu(!dropMenu)}
                      >
                        <div className="flex gap-1 justify-center items-center">
                          {nav.title}
                          <HiChevronDown />
                        </div>
                        
                      </p>
                      <div className={`${dropMenu ? "lg:h-0 h-[330px] transition-all duration-500" 
                        : "h-0 transition-all duration-500"}`}>
                      <MobileDropMenu />
                    </div>
                    </>
                    ) : (
                      <a
                        href={`/#${nav.id}`}
                          onClick={handleClickNavLink}
                      >
                        {nav.title}
                      </a>
                    )}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="flex justify-center items-center gap-1 text-white">
                  <FaEnvelope />
                  <a href="mailto:info@mactech.qa">info@mactech.qa</a>
                </div>
              </div>
            </div>
          </div>
          <div className={`${dropMenu ? "hidden lg:block" : "hidden"}`}>
            <DropMenu background={background} />
          </div>
        </div>
      </nav>

      <Outlet />
    </>
  );
}

function DropMenu({background}) {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div
      className={`flex gap-16 justify-center py-8 mt-4
     ${
      background || offset > 0
         ? "border-black bg-white text-black border-t-[1px]"
         : "border-white bg-primaryBlue text-white border-2  "
     } ${background && offset === 0 && "border-2"}`}
    >
      <div className="grid grid-cols-2 gap-y-4 gap-x-16 p-8">
        {elvSystem.map((elv) => (
          <Link to={`/ElvSystem/${elv.id}`}>{elv.title}</Link>
        ))}
      </div>
      <div className=" flex flex-col justify-center items-start gap-2 max-w-[400px] flex-1">
        <h1 className="font-bold">Elv System</h1>
        <p className="text-xs">
          We provide all the new modern technologies that are increasingly
          becoming must-have systems in every building
        </p>
        <p className="text-xs pt-4">Do you need a pdf Elv system profile?</p>
        <Button
          title="Download"
          pathFile="/profile-pdf/Machtech-ELV-Profile.pdf"
        />
      </div>
    </div>
  );
}