export default function Client(props) {
  return (
    <div
      className={`p-1 pb-2 md:pb-4 rounded-md shadow-primaryBlue h-full duration-500 ${
        props.active ? "scale-110" : " opacity-70"
      }`}
      style={
        props.active
          ? {
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
            }
          : {
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            }
      }
    >
      <div className="flex flex-col gap-2 sm:gap-8 justify-center items-center">
        <div className="w-full h-[80px]">
          <img
            src={props.image}
            alt="client"
            className="w-full h-full object-fill"
          />
        </div>
        <h1 className="text-black text-[8px] sm:text-xs text-center px-2 sm:px-4">
          {props.name}
        </h1>
      </div>
    </div>
  );
}
