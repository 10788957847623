import image from "../assets/background-bg.jpg";
import video from "../assets/video.mp4";

export default function ParallaxBg() {
  return (
    <>
      <div className=" fixed bottom-0 right-0 h-full w-full min-h-full min-w-full -z-[100]">
        <video
          autoplay="true"
          muted
          className=" w-full h-full object-cover"
          loop
        >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="h-[250px] md:h-[400px] lg:h-[550px]"></div>
    </>
  );
}
